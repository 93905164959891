.buycrypto {
  padding: 5%;
}
.buycrypto h1 {
  text-align: center;
}
.modal-confirm {
  padding: 6% 0;
  text-align: center;
  background-color: var(--berge);
}
.transparent-modal {
  background-color: transparent;
  box-shadow: none;
}
.modal-confirm .load {
  display: flex;
  justify-content: center;
  margin: 20px;
}
.modal-confirm span {
  padding: 1%;
  color: var(--red);
  border-bottom: 1px solid;
}
.buycrypto .rate {
  position: relative;
  margin: 25px 0px;
  padding: 5%;
  text-align: center;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 5px;
}
.buycrypto .rate div {
  color: var(--red);
  font-size: 1.1em;
  font-weight: bold;
}
.rate span {
  display: block;
  margin-top: 10px;
  color: var(--red);
  font-size: 0.6em;
  text-align: right;
}
.buycrypto .form {
  padding: 5%;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 5px;
}
.buycrypto .form-group {
  margin: 20px 0px;
}
.buycrypto button {
  display: block;
  width: 100%;
  height: 50px;
  background: var(--red);
  border: var(--no-value);
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--white);
  transition: all 1s ease-out;
}
.buycrypto button:disabled {
  cursor: not-allowed;
  background: var(--transparent2);
}
.opid {
  text-align: center;
}
.opid h3 {
  margin: 5px;
  color: var(--red);
}

.disjoint{
  width: 92%;
  color: #842029;
  background-color: #f8d7da;  
  border-color: #f5c2c7;
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.crypt-title{
  width: 80%;
  margin: 0 auto;
  font-size: 1em;
  text-align: center;
  font-weight: unset!important;
}
.buy-title,.wallet-div{
  padding: 0 20px;
}
.buycrypto .choisix  {
  display: flex;
}
.buycrypto .choisix .choix1  {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 5%;
  width: 33.3%;
  background-color: #F6EFEF;
}
.buycrypto .choisix .choix2  {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2% 5%;
  width: 90%;
  background-color: #F6EFEF;
}
.buycrypto .choisix .choix1  input{
  width: 100%;
  padding: 0% ;
  color: var(--red);
  font-weight: bold;
  transition: ease 0.3s;
  cursor: pointer;
}
.buycrypto .choisix .choix1  input:hover{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  /* border: 1px solid black; */
  border-radius: 2px;
  font-weight: bold;
  /* font-size: x-large; */
  color: var(--red);
  opacity: 0.8;
  border: 1px solid black;
}
.buycrypto .choisix .choix1  .lig{
  width: 100%;
  padding: 0% ;
  border: 1px solid gray;
  /* background-color: #F6EFEF; */
  color: #cc1616;
  font-weight: bold;
  transition: ease 0.3s;
  cursor: pointer;
  display: inline-block;
  font-size: x-large;
  padding: 4%;
  border-radius: 3px;
}
#lig{
  background-color: #F6EFEF;
}
.buycrypto .choisix .choix1  .lig:hover{
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
  border-radius: 2px;
  font-weight: bold;
  color: var(--red);
  /* opacity: 0.8; */
  border: 1px solid black;
  border-radius: 3px;
}
.cryptoActif{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px!important;
  background-color: var(--red)!important;
  border-radius: 2px!important;
  font-weight: bold!important;
  color: var(--white)!important;
  border: 1px solid var(--red)!important;
}

.buycrypto .imgBtc{display: none;}
.buycrypto .btc{
  display: block;
}
.buycrypto .list_usdt{
  position: absolute;
  left: 3%;
  top: 5%;
  cursor: pointer;
  transition: 1s;
}
.buycrypto .list_usdt span{
  width: 100%;
  margin: 0;
  padding: 10%;
  font-size: 0.85em !important;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.MuiMenuItem-root .image_crypto{
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 0;
}
.buycrypto .MuiMenu-paper{
  top: 170px;
}

.choisix ul.dropdown li{
  display: block;
  border: 1px solid rgba(128, 128, 128, 0.239);
  color: var(--red)!important;
  font-size: x-large!important;
  cursor: pointer;
  padding: 4%;
}
.choisix ul.dropdown li:hover{
  background-color: rgba(128, 128, 128, 0.151);
  font-size: x-large!important;
}
.choisix ul.dropdown{
  width: 70%;
  background-color: var(--berge);
  position: absolute;
  top: 65px;
  left: 15%;
  z-index:100;
  display: none;
}
.choisix li:hover ul.dropdown{
  display: block;
}
.choisix .choix1 .fle_down{
  display: flex;
  align-items: self-end;
  justify-content: center;
}


@media screen and (max-width: 568px) {
  
  .buycrypto .choisix .choix1  .lig{
    font-size: x-small;
  }
  .choisix ul.dropdown li{
    font-size: small!important;
  }
  .choisix ul.dropdown {
    top: 30px;
  }
  
}
@media screen and (min-width: 568px) {
  
  .buycrypto .choisix .choix1  .lig{
    font-size: x-small;
  }
  .choisix ul.dropdown {
    top: 35px;
  }
  .choisix ul.dropdown li{
    font-size: small!important;
  }

}
@media screen and (min-width: 768px) {
  .disjoint {
    width: 77%;
    margin: 0 auto;
  }
  .buy-container {
    width: 80%;
    margin: auto;
    font-size: 1.5em;
  }
  .buycrypto .rate {
    padding: 3%;
  }
  .modal-confirm span {
    cursor: pointer;
  }
  .buy-title{
    font-size: 35px;
    padding: 0 35px;
  }
  .wallet-div{
    font-size: 25px;
  }
  .buycrypto h1 {
    font-size: 1.7em;
  }
  .buycrypto .choisix  {
    margin-top: 10px;
  }
  .buycrypto .choisix .choix1  input{
    font-size: x-large;
    padding: 4%;
  }
  .buycrypto .choisix .choix1  .lig{
    font-size: 20px;
    font-weight: bold;
    padding: 4%;
  }
  .buycrypto .imgBtc{
    display: block;
    width: 25px;
    height: 25px;
    transform: translateX(170%);
  }
  .choisix ul.dropdown {
    top: 52px;
  }
  .choisix ul.dropdown li{
    font-size: large!important;
  }
}
@media screen and (min-width: 992px) {
  .choisix ul.dropdown {
    top: 65px;
  }
  .choisix ul.dropdown li{
    font-size: x-large!important;
  }
}

