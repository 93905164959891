.crypto_list{
    width: 40%;
}
.crypto_list span{
    color: white!important;
    display: flex;
    margin-top: 0!important;
   
}
.crypto_list .flec{
    transform: translate(3px,3px);
}
.crp_tof{
    width: 25px;
    height: 25px;
    filter: grayscale(0.9);
}
.plex{
    width: 130px;
    padding: 5px  2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (min-width: 568px) {
     #plet{
        font-size: 15px!important;
    }
    .plex{
        width: 150px;
    }
}
@media screen and (min-width: 768px) {
    .crypto_list .plex{
        font-size: 13px!important;
    }
    #plet{
        font-size: 13px!important;
    }
    .crypto_list {
        width: 30%;
    }
}
@media screen and (min-width: 992px) {
    .crypto_list .plex{
        font-size: 20px!important;
    }
    .crypto_list #plet{
        font-size: 18px!important;
    }
    .plex{
        width: 200px;
    }
}