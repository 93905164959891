.m2customModal{
    border-radius: 8px;
    text-align: center;
}
.m2customModal h2 {
    color: red;
    padding: 0 ;
}
.m2customModal .icon-ind{
    padding: .5rem;
}
.m2customModal p{
    text-align: center;
}
.content2 .body-content2 .right{
    margin: 0;
    padding:  7px 15px;
    text-align: center;
} 
.content2 .body-content2 .left{
    padding: 7px;
}

@media screen and (min-width: 500px) {
    .m2customModal{
    max-width: 35vw;
    }
    .m2customModal h2 {
        padding: 7px 12px ;
    }
}
@media screen and (min-width: 992px) {
    .m2customModal{
    max-width: 32vw;
    }
    .m2customModal h2 {
        padding: 7px 12px ;
    }
}

