.sendmoney {
    padding: 5%;
}

.sendmoney .form {
    padding: 6.5% 5%;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 5px;
}

.sendmoney .form-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
}
.sendmoney .form-heads {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}
.mob{
    margin: 15px 0!important;
}
.to-top{
    margin-top: 25px;
}
.invisible{
    /* color: white!important; */
    visibility: hidden;
}

.form-head>div:nth-child(1) {
    flex: 1;
    margin-right: 5%;
}

.form-head>div:nth-child(2) {
    color: var(--red);
    font-weight: bold;
    text-align: right;
    /* font-size: smaller; */
    transform: translate(25px, 0px);
}

.sendmoney .form-group {
    margin: 35px 0px;
    width: 95%;
}
.sendmoney .form-groups {
    width: 95%;
    margin-right: 0!important;
}

.sendmoney button {
    display: block;
    width: 100%;
    height: 50px;
    background: var(--red);
    border: var(--no-value);
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: bold;
    color: var(--white);
    text-align: center;
    transition: all 1s ease-out;
}

.sendmoney button:disabled {
    cursor: not-allowed;
    background: var(--transparent2);
}

.summary {
    margin: 20px 0px;
    padding: 3% 5%;
    background: var(--red);
    border-radius: 5px;
    color: white;
}

.summary .row {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 1%;
    border-top: 3px solid var(--white);
    font-size: 1.2em;
}
.summary .row1{
    font-size: 1.3em;
    margin-top: 15px;
}

.summary .warning {
    background: var(--white);
    border-radius: 8px;
    color: var(--black);
    /* margin-top: 20px; */
}

.warning h4 {
    padding: 1% 5%;
    border-bottom: 1px solid var(--transparent2);
    color: var(--red);
    margin: 5px 0;
}

.warning p {
    padding: 0% 5%;
    margin: 0px;
}

.warning h2 {
    margin: 0px;
}

.sendmoney .sign {
    display: inline;
    margin: 0px;
}

.eclips {
    padding: 10px;
}
.disjointe{
  width: 82.5%;
  color: #842029;
  margin: 5rem auto 2rem auto;
  background-color: #f8d7da;  
  border-color: #f5c2c7;
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

@media screen and (min-width: 992px) {
    .disjointe{
        width: 91.5%;
        margin: 0 auto;
    }
    .sendmoney {
        display: flex;
        justify-content: space-between;
        padding: 3%;
    }
    .sendmoney .form-head {
        flex-direction: row;
    }

    .sendmoney .form {
        width: 48%;
        padding: 2.5% 5%;
    }

    .summary {
        width: 48%;
        margin: 0px;
        margin-left: 20px;
    }

    .eclips {
        padding: 10px;
    }

    .delay {
        animation: eclip 0.5s cubic-bezier(.17, .67, .82, -0.04);

    }
    .sendmoney .form-group {
        margin: 35px 0px;
        width: 100%;
    }
    .sendmoney .form-groups {
        width: 100%;
    }
    .mob{
        margin: 10px 0!important;
    }
    .sendmoney .form-groups {
        margin: 15px 0!important;
    }

    @keyframes eclip {
        0% {
            transform: translateX(25px);
            opacity: 0;
        }

        100% {
            transform: translateX(-25px);
            opacity: 1;
        }

    }
}