.sellcrypto {
  padding: 5%;
}
.sellcrypto h1 {
  text-align: center;
}
.sellcrypto .rate {
  position: relative;
  margin: 25px 0px;
  padding: 5%;
  text-align: center;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 5px;
}
.sellcrypto .rate div {
  color: var(--red);
  font-size: 1.1em;
  font-weight: bold;
}
.rate span {
  display: block;
  margin-top: 10px;
  color: var(--red);
  font-size: 0.6em;
  text-align: right;
}
.sellcrypto .form {
  padding: 5%;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 5px;
}
.sellcrypto .form-group {
  margin: 20px 0px;
}
.sellcrypto button {
  display: block;
  width: 100%;
  height: 50px;
  background: var(--red);
  border: var(--no-value);
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--white);
  transition: all 1s ease-out;
}
.sellcrypto button:disabled {
  cursor: not-allowed;
  background: var(--transparent2);
}
.sellcrypto .disjoint{
  width: 93%;
  color: #842029;
  margin: 0 auto;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.modal-container{
  padding: 10px auto;
}
.sellcrypto .crypt-sell{
  width: 80%;
  margin: 0 auto;
  font-size: 1em;
  text-align: center;
  font-weight: unset!important;
}

.sellcrypto .choisix  {
  display: flex;
}
.sellcrypto .choisix .choix1  {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 5%;
  width: 33.3%;
  background-color: #F6EFEF;
}
.sellcrypto .choisix .choix1  input{
  width: 100%;
  padding: 0% ;
  color: var(--red);
  font-weight: bold;
  transition: ease 0.3s;
  cursor: pointer;
}
.sellcrypto .choisix .choix1  input:hover{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 2px;
  font-weight: bold;
  /* font-size: x-large; */
  color: var(--red);
  opacity: 0.8;
  border: 1px solid black;
}
.sellcrypto .choisix .choix1  .lig{
  width: 100%;
  padding: 0% ;
  border: 1px solid black;
  /* background-color: #F6EFEF; */
  color: #cc1616;
  font-weight: bold;
  transition: ease 0.3s;
  cursor: pointer;
  display: inline-block;
  font-size: x-large;
  padding: 4%;
  border-radius: 3px;
}
#lig{
  background-color: #F6EFEF;
  font-size: large;
}
.sellcrypto .choisix .choix1  .lig:hover{
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
  border-radius: 2px;
  font-weight: bold;
  color: var(--red);
  /* opacity: 0.8; */
  border: 1px solid black;
  border-radius: 3px;
}
.sellcrypto .list_usdt{
  position: absolute;
  left: 3%;
  top: 5%;
  cursor: pointer;
  transition: 1s;
}
.sellcrypto .list_usdt span{
  width: 100%;
  margin: 0;
  padding: 10%;
  font-size: 0.85em !important;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.choisix ul.dropdown li{
  display: block;
  border: 1px solid rgba(128, 128, 128, 0.239);
  color: var(--red)!important;
  font-size: large!important;
  cursor: pointer;
  padding: 4%;
}
.choisix ul.dropdown li:hover{
  background-color: rgba(128, 128, 128, 0.151);
  font-size: large!important;
}
.choisix ul.dropdown{
  width: 70%;
  background-color: var(--berge);
  position: absolute;
  top: 65px;
  left: 15%;
  z-index:100;
  display: none;
}
.choisix li:hover ul.dropdown{
  display: block;
}
.cryptoActif{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px!important;
  background-color: var(--red)!important;
  border-radius: 2px!important;
  font-weight: bold!important;
  color: var(--white)!important;
  border: 1px solid var(--red)!important;
}

@media screen and (max-width: 568px) {
  
  .sellcrypto .choisix .choix1  .lig{
    font-size: x-small;
  }
  .choisix ul.dropdown li{
    font-size: small!important;
  }
  .choisix ul.dropdown {
    top: 30px;
  }
  #lig{
    background-color: #F6EFEF;
    font-size: x-small;
  }
  .choisix ul.dropdown li:hover{
    background-color: rgba(128, 128, 128, 0.151);
    font-size: small!important;
  }
  
}
@media screen and (min-width: 568px) {
  
  .sellcrypto .choisix .choix1  .lig{
    font-size: x-small;
  }
  .choisix ul.dropdown {
    top: 35px;
  }
  .choisix ul.dropdown li{
    font-size: small!important;
  }
  #lig{
    background-color: #F6EFEF;
    font-size: small;
  }

}

@media screen and (min-width: 768px) {
  .sellcrypto .disjoint{
    width: 77.5%;
  }
  .sell-container {
    width: 80%;
    margin: auto;
    font-size: 1.5em;
  }
  .sellcrypto .rate {
    padding: 3%;
  }
  .sellcrypto h1 {
    width: 80%;
    margin: 15px auto;
    font-size: 1.7em;
  }
  .sellcrypto .choisix  {
    margin-top: 10px;
  }
  .sellcrypto .choisix .choix1  input{
    font-size: x-large;
    padding: 4%;
  }
  .choisix ul.dropdown {
    top: 52px;
  }
  .choisix ul.dropdown li{
    font-size: large!important;
  }
}

@media screen and (min-width: 992px) {
  .choisix ul.dropdown {
    top: 60px;
  }
  .choisix ul.dropdown li{
    font-size: large!important;
  }
  #lig{
    background-color: #F6EFEF;
    font-size: x-large;
  }
}