/* @font-face {
    font-family: 'proxima-nova';
    src: url('../assets/font/proxima-nova.otf');
} */
.more{
    background-color: rgba(22,22,22,0.10);
    padding-top: 80px;
    padding-bottom: 40px;
}
.more .more-section{
    width: 70%;
  margin: 0px auto;
  text-align: center;
  background-color: #ffffff;
  padding: 40px 7%;
  color: #6E747B;
}
.more .more-section .ligne-rouge{
    width: 10%;text-align: left;padding-bottom: 5px;
    border-bottom:2px solid #ee5253;
}

.more-title{
    text-align: center;
}
.more-mot{
    text-align: left;
    padding: 5%;
    border-bottom: 2px solid var(--red);
}
.more .player{
    width: 100%;
    border-radius: 4px;
    margin: 2px auto;
    background-size: cover;
    background-position: center;
}
.more .img-phone{
    display: inline-block;
    padding: 5px;
    width: 20px;
}
.more .more-section li{
    font-size: 21px;
    text-align: left;
}
.more .more-section h3{
    text-align: left;
    padding-left: 2%;
}
.more .more-section p{
    text-align: left;
    font-size: 21px;
    margin-top: 0;
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@media only screen and (min-width:992px){
    .more .player{
        width: 100%;
    }
    .para-li{
        padding-left: 7%;
    }
}