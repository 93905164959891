.about {
  margin: 50px auto;
  padding: 4%;
}
.about h2 {
  text-align: center;
  font-size: 2em;
}
.about h3 {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    /* transform: translateY(-10px); */
    width: 70%;
    margin: 0 auto;
}
.about .finding{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    font-size: 1.9em;
    font-weight: bold;
}
.about .ligne {
    display: block;
    width: 100px;
    margin: 0 auto;
    margin-top: 8px;
    background: var(--red);
    border: 3px solid var(--red);
    border-radius: 10px;
}
.about .picture {
  display: block;
  width: 100%;
  margin: 40px 0px;
}
.about button {
  display: block;
  margin: 20px 0px;
  padding: 4%;
  background: var(--red);
  border: none;
  border-radius: 10px;
  color: var(--white);
  font-size: 1.2em;
  font-weight: bold;
  transition: all 2s ease-out;
}
.about button a {
  color: var(--white);
}
.about .sentence {
  display: flex;
  margin: 10px auto;
  align-items: center;
}
.about .sentence img {
  display: block;
  width: 50px;
  color: var(--red);
}

.about a img {
  /* border: 1px solid #000; */
  max-height: 50px;
}
.about .about-descrit{
  text-align: left!important;
  font-weight: unset!important;
}

@media screen and (min-width: 902px) {
  .about-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .about .picture {
    width: 45%;
  }
  .about-container > div {
    width: 45%;
  }

  .about button {
    display: block;
    border: none;
    background: inherit;
    color: var(--white);
  }
  .about button:hover{
      color: var(--red);
  }
  .about .btn-flip{
    display: block;
    position: relative;
    padding: 5%;
    width: 100%;
    color: var(--red);
    border: 2px solid var(--red);
    border-radius: 10px;
    font-weight: bold;
    overflow: hidden;
    transition: all 0.6s ease-out;
}
.about .btn-flip:hover{
    color: var(--white);
}
/* -- apparition oblique --*/
.about .btn-flip::before{
    background-color: var(--red);
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%,-50%) rotate(35deg);
    transition: all 0.6s ease-out;
    width: 0%;
    height: 350%;
}
.about .btn-flip:hover::before{
    width: 380%;
}
  
}

@media screen and (min-width: 1200px) {
    .about-container {
        font-size: 1.1em;
    }
    .about button {
        padding: 2% 4% 2% 0;
    }
}
