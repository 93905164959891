.forget{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
}
.txt-load{
    text-align: center;
}
.MuiButton-label{
    display:flex!important;
    justify-content: center!important;
    align-items: center!important;
}
.forget-form .MuiFormControl-root{
    margin: 33px 0;
}
.MuiButton-root:hover {
    background-color: var(--red)!important;
}

.forget-form{
    /* font-family: 'montserat'; */
    width:75%;
    margin: 0px auto 20px auto;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.forget-title{
    padding-left: 20px;
    /* font-family: 'montserat'; */
    color: #262626;
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.53em;
    margin-block-end: 0.53em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.forget-paragraph{
    color: #262626;
    font-size: 1rem;
    font-family: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.5;
}
.forget-form .login-form .form-groupe{
    margin: 12px 0 12px 0;
}
/* .forget-btn{
    margin: 7px 0 10px 0;
    color: var(--white);
    padding: 7px 15px;
    border-radius: 4px;
    border:none;
    font-size:18px;
    background-color: var(--red);
    font-weight: 900;
    line-height: 1.2;
    letter-spacing:  0.02857em;
    cursor: pointer;
    font-family: 'roboto';
    transition: all 1s ease-out;
}*/
.forget-btn:disabled{
    background-color: var(--transparent2);
} 
.forget-form button {
    display: block;
    width: 100%;
    height: 50px;
    background: var(--red);
    border: var(--no-value);
    border-radius: 5px;
    font-size: 1.2em;
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--white);
    transition: all 1s ease-out;
  }
  .forget-form button:disabled {
    cursor: not-allowed;
    background: var(--transparent2);
  }
  .forget .btn-resend{
    padding: 0 0 3px 0;
    margin: 25px;
    display: inline-block;
    border-bottom: 1px solid var(--black);
    color: var(--red);
    cursor: pointer;
  }
  .forget .btn-resend:hover{
    border-bottom: 1px solid var(--red);
  }
  .forget .form-inp{
    margin: 30px auto;
  }

@media only screen and (min-width: 576px) {
    /*--site mode tablette--*/
    
    .forget-paragraph{
        margin-top: 25px;
    }
    .forget-form{
        /* font-family: 'montserat'; */
        width: 55%;
        margin: 0 auto 35px;
        padding: 16px 30px 15px 30px ;
        border: 1px solid #eeeeee;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px;
    }
    .forget-title{
        padding-left: 18%;
        /* font-family: 'montserat'; */
        color: #262626;
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.53em;
        margin-block-end: 0.53em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .forget-btn{
        margin: 7px 0 30px 0;
        padding: 7px 30px;
        border-radius: 4px;
    }
    .forget .form-inp{
        margin: 15px auto;
      }

}
@media only screen and (min-width: 776px) {
    .forget-form {
        width: 45%;
        margin: 0px auto 55px auto;
    }
    .forget-title{
        padding-left: 22%;
        padding-top: 25px;
    }
}

@media only screen and (min-width: 992px) {
    /*--site mode tablette--*/
    
    /* .forget-title{
        padding-top: 35px;
    } */
    .forget-form{
        /* font-family: 'montserat'; */
        width: 35%;
        margin: 0 auto 40px;
        border: 1px solid #eeeeee;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px;
    }
    .forget-title{
       text-align: center;
        /* font-family: 'montserat'; */
        color: #262626;
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.53em;
        margin-block-end: 0.53em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        padding: 0;
    }
    .forget-btn{
        display: block;
        height: 50px;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        color: var(--white);
    }
}