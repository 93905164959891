.operations {
    /* margin: 30px 0px; */
    padding: 20px 5%;
    background: var(--red);
    color: var(--white);
    text-align: center;
}
.operations input {
    display: block;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 1.3em;
    text-align: center;
    outline: none;
}
.button-operation {
    margin: 25px auto;
}
.operations button {
    display: block;
    width: 100%;
    height: 55px;
    margin: 10px 0px;
    background: var(--transparency);
    border: 1px solid var(--white);
    border-radius: 8px;
    font-size: 1em;
    font-weight: bold;
    color: white;
}
.button-first{
    font-size: 1.3em;
}
.button-operation div {
    display: flex;
    justify-content: space-between;
}
.button-operation div button {
    width: 48%;
}
.operations .picture {
    display: none;
}
.listen{
    display: flex;
    position: relative;
    z-index: 1;
}
.contri{
    width: 12%;
}

@media screen and (min-width: 768px) {
    
    .operations {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .operations .picture {
        display: block;
        width: 50%;
    }
}

@media screen and (min-width: 1200px) {
    .theader{
        display: block;
        font-size: 3em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .operations {
        justify-content: space-around;
    }
    .operations-container {
        width: 45%;
    }
     .operations h1 {
        margin-top: 3vh;
        font-size: 3em;
    }
    .operations input {
        height: 50px;
        font-size: 1.8em;
    }
    .operations button {
        height: 65px;
        margin-top: 3vh;
        font-size: 1.5em;
    }
    .operations .picture {
        width: 50%;
    }
    .listen{
        display: flex;  
    }
    .contri{
        width: 14.5%;
    }
    
    .btn-neon{
        position: relative;
        display: inline-block;
        color: #ffffff;
        /* text-transform: uppercase; */
        text-decoration: none;
        overflow: hidden;
        transition: 0.2s;
    }
    .btn-neon:hover{
        color: var(--white);
        box-shadow: 0 0 5px #ffffff, 0 0 10px #5fb0f3,0 0 20px #99caf1;
        transition-delay: 1s;
    }
    .btn-neon span{
    display: block;
    position: absolute;
    }
    .btn-neon span:nth-child(1){
        top: 0;
        left: -100%;
        width: 100%;
        height: 3px;
        background: linear-gradient(90deg,transparent,#ffffff);
    }
    .btn-neon:hover span:nth-child(1){
        top: 0;
        left: 100%;
        transition: 1s;
    }
    .btn-neon span:nth-child(3){
        bottom: 0;
        right: -100%;
        width: 100%;
        height: 3px;
        background: linear-gradient(270deg,transparent,#ffffff);
    }
    .btn-neon:hover span:nth-child(3){
        right: 100%;
        transition: 1s;
        transition-delay: 0.5s;
    }
    .btn-neon span:nth-child(2){
        top: -100%;
        right: 0%;
        width: 3px;
        height: 100%;
        background: linear-gradient(1800deg,transparent,#ffffff);
    }
    .btn-neon:hover span:nth-child(2){
        top: 100%;
        transition: 1s;
        transition-delay: 0.25s;
    }
    .btn-neon span:nth-child(4){
        bottom: -100%;
        left: 0%;
        width: 3px;
        height: 100%;
        background: linear-gradient(360deg,transparent,#ffffff);
    }
    .btn-neon:hover span:nth-child(4){
        bottom: 100%;
        transition: 1s;
        transition-delay: 0.75s;
    }
    .operations .taille{
        font-size: 15px;
    }
}